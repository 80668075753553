<template>
  <AView
    :value="value"
    :active="businessDomain.id === activeBusinessDomainId"
    :name="businessDomain.name"
    ref="businessDomain-editor-view"
    @onHover="onHover"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
    @onClose="(e) => $emit('onClose', e)"
    @action="(action) => action.handler({ from: value, businessDomain })"
  >
    <template v-slot:content="{}">
      <v-card class="transparent mt-4 pa-2 elevation-0">
        <v-card-text>
          <BusinessDomainEditorVue
            ref="form"
            v-model="businessDomain"
            :permissions="value.permissions"
            @onLinkCreate="onLinkCreate"
            @onLinkRemove="onLinkRemove"
            @action="(action) => action.handler({ from: value, businessDomain })"
          ></BusinessDomainEditorVue>
        </v-card-text>
      </v-card>
    </template>
  </AView>
</template>
    
    
<script>
import { mapGetters, mapState } from "vuex";
import BusinessDomainEditorVue from "../../../../../../components/wad/organisms/knowledgeBase/businessDomains/forms/editor/BusinessDomainEditor.vue";
import AView from "../../../../../../components/wad/organisms/layout/A-View.vue";

export default {
  props: {
    value: {
      default: () => ({}),
    },
  },
  components: {
    AView,
    BusinessDomainEditorVue,
  },
  data() {
    return {
      updatedTimeout: undefined,

      businessDomain: {
        searchableKeyword: {},
      },
    };
  },
  computed: {
    ...mapState("BusinessDomainStore", ["activeBusinessDomainId", "displayedBusinessDomains"]),
    ...mapGetters("BusinessDomainStore", ["businessDomainById"]),
  },
  created() {
    this.$store.dispatch("BusinessDomainStore/GetBusinessDomain", {
      id: this.value.relation.params.businessDomainId,
    });

    this.businessDomain = this.businessDomainById(this.value.relation.params.businessDomainId);
  },
  methods: {
    onHover() {
      this.$store.commit(
        "BusinessDomainStore/setActiveId",
        this.value.relation.params.businessDomainId
      );
    },
    async onLinkCreate(link) {
      const newLink = await this.$store.dispatch(
        "KnowledgeBaseStore/CreateLink",
        {
          link: { link },
        }
      );

      this.businessDomain.links.push({ link: newLink });
    },
    async onLinkRemove(link) {
      this.businessDomain.links = this.businessDomain.links.filter(
        (el) => el.link_id !== link.id
      );
    },
  },

  watch: {
    displayedBusinessDomains: {
      async handler(newVal) {
        if (newVal) {
          this.businessDomain = this.businessDomainById(
            this.value.relation.params.businessDomainId
          );
        }
      },
      deep: true,
    },
    businessDomain: {
      async handler(newVal, oldVal) {
        if (!(oldVal && oldVal.id)) return;

        if (this.$refs.form && this.$refs.form.validate())
          await this.$store.dispatch("BusinessDomainStore/UpdateBusinessDomain", {
            businessDomain: newVal,
          });
      },
      deep: true,
    },
  },
};
</script>