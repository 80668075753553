<template>
  <v-form ref="form" v-model="pValid" lazy-validation>
    <HelpFormInputVue
      dark
      v-model="form.name"
      :rules="nameRules"
      label="Business Domain name"
      :helpText="'Please name a domain.'"
      required
    ></HelpFormInputVue>

    <HelpFormInputVue
      dark
      v-model="form.searchableKeyword.name"
      :rules="nameRules"
      label="Searchable Keyword"
      :helpText="'This text will be used to adjust a search in the right way. E.g. this data will be used in Leads-Analyzer'"
      :rows="4"
      :textarea="true"
      required
    ></HelpFormInputVue>

    <HelpFormInputVue
      dark
      :rows="4"
      v-model="form.description"
      :rules="descriptionRules"
      label="Description"
      :helpText="'The description of the Business Domain'"
      :textarea="true"
      required
    ></HelpFormInputVue>

    <HelpFormInputVue
      dark
      :rows="4"
      v-model="form.main_topics"
      label="Main Experience Topics"
      :helpText="'These are the main topics that ADAAS team experienced in'"
      :textarea="true"
      required
    ></HelpFormInputVue>

    <HelpFormInputVue
      dark
      :rows="4"
      v-model="form.experience_overview"
      label="Experience overview"
      :helpText="'Please provide an overview of the Experience in the particular business domain. This description will be used to put inside the slides and should transparently says what experience we have.'"
      :textarea="true"
      required
    ></HelpFormInputVue>

    <!-- Slides Configurations -->
    <FormSection
      :label="'Slides'"
      :icon="'mdi-play-box-outline'"
      underline
      :editable="false"
      right
    ></FormSection>

    <SelectorDefault
      dark
      class="mt-6"
      label="Slide Image"
      v-model="form.slide_image_key"
      :items="slideImages"
      :helpText="'Please Select an Image that will be used in the presentations'"
      required
      hide-details
      clearable
    ></SelectorDefault>
  </v-form>
</template>
      
      
<script>
import HelpFormInputVue from "../../../../../atoms/common/inputs/HelpFormInput.vue";
import { PermissionsHelper } from "../../../../../../helpers/permissions.helper";
import { TAGS_BUTTONS } from "../../../../../../../views/src/apps/knowledge-base/constants/actions.gh";
import FormSection from "../../../../../atoms/common/FormSection.vue";
import SelectorDefault from "../../../../../atoms/common/autocompletes/SelectorDefault.vue";
import { CASE_STUDIES_SLIDES_IMAGES } from '../../../../../../constants/defaults/SlidesImages';

export default {
  props: {
    value: {},
    valid: {
      type: Boolean,
      default: false,
    },
    onLinkCreate: {
      type: Function,
      default: async () => {},
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    tagActions: function () {
      return PermissionsHelper.getActions(
        [TAGS_BUTTONS.CREATE_NEW],
        this.permissions
      );
    },
  },
  data() {
    return {
      // Form
      pValid: this.valid,
      form: this.value,

      newLink: "",

      // ======rules
      nameRules: [(v) => !!v || "Term Name is required"],
      descriptionRules: [(v) => !!v || "Description is required"],

      linkRules: [
        (v) =>
          !v || /(https?:\/\/[^\s]+)/.test(v) || "Link should be a proper link",
      ],

      slideImages: CASE_STUDIES_SLIDES_IMAGES
    };
  },
  components: {
    HelpFormInputVue,
    FormSection,
    SelectorDefault,
  },
  methods: {
    prepareLists(value) {
      let tags = [];

      if (value.tags && value.tags.length) tags = value.tags.map((t) => t.tag);

      return { tags };
    },

    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    onNewLinkCreate() {
      this.$emit("onLinkCreate", this.newLink);
    },
    onLinkRemove(link) {
      this.$emit("onLinkRemove", link);
    },
    onAddAnswer() {
      if (this.form.answers.length)
        this.form.answers.splice(0, 0, {
          name: "New  555",
        });
      else
        this.form.answers.push({
          name: "New Answer ",
        });
    },
    onRemoveAnswer(answer) {
      const answerIndex = this.form.answers.findIndex(
        (ans) => ans.id === answer.id
      );
      this.form.answers.splice(answerIndex, 1);
    },
  },
  watch: {
    value: {
      handler(newVal) {
        this.form = newVal;
      },
      deep: true,
    },
    form: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
    pValid(newVal) {
      this.$emit("onValid", newVal);
    },
  },
};
</script>
      
      
<style lang="scss" scoped>
.term-col {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  flex-grow: 1;
  max-width: 100%;
}
</style>